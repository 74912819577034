import React, { useEffect, useState } from "react"

import { TbMenuDeep } from "react-icons/tb"
import { AiOutlineClose } from "react-icons/ai"
import { IoMdSettings } from "react-icons/io"
import { GiStoneAxe } from "react-icons/gi"
import { AiFillHome } from "react-icons/ai"
import { FaUserFriends } from "react-icons/fa"
import { FaRoad } from "react-icons/fa"
import { FaRegNewspaper } from "react-icons/fa6"
import { GiReceiveMoney } from "react-icons/gi"
import { RiLogoutBoxLine } from "react-icons/ri"

export default function Navbar() {
  const [isSidebar, setSidebar] = useState(false)
  const [isUser, setUser] = useState("")

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")))
  },[localStorage.getItem("user")])
  
  function LogOut() {localStorage.removeItem("user"); window.location.href = "/login"; setUser("")}

  return (<>
    <div className="navbar">
      <div className="maxwidth">
        <div className="logo"><img src={require("../assets/navbar.png")} alt=""/></div>
        <div className="link pcsize">
          <a href="/">Home</a>
          <a href="/mining">Mining</a>
          <a href="/account">Account</a>
          <a href="/team">Referral</a>
          <a href="/roadmap">Roadmap</a>
          <a href="/whitepaper">Whitepaper</a>
          {isUser ? <button className="button-89" onClick={()=>LogOut()}><div className="icon"><RiLogoutBoxLine/>Sign Out</div></button>:
                    <button className="button-89" onClick={()=>window.location.href="/login"}><div className="icon"><RiLogoutBoxLine className="login"/>Sign In</div></button>}
        </div>
        <div className="link phonesize">
          <button onClick={()=>window.location.href="/"}><AiFillHome/></button>
          <button onClick={()=>window.location.href="/mining"}><GiStoneAxe/></button>
          <button onClick={()=>window.location.href="/account"}><IoMdSettings/></button>
          
          {isSidebar ? 
            <button onClick={()=>setSidebar(false)}><AiOutlineClose/></button>:
            <button className="menu-button" onClick={()=>setSidebar(true)}><TbMenuDeep/></button>           
          }
        </div>

        {isSidebar && 
          <div className="sidebar">
            <div className="item"><div className="icon"><AiFillHome/></div>
              <a href="/">Home</a>
            </div>
            <div className="item"><div className="icon"><GiStoneAxe/></div>
              <a href="/mining">Mining</a>
            </div>
            <div className="item"><div className="icon"><IoMdSettings/></div>
              <a href="/account">Account</a>
            </div>
            <div className="item"><div className="icon"><FaUserFriends/></div>
              <a href="/team">Team</a>
            </div>
            <div className="item"><div className="icon"><FaRoad/></div>
              <a href="/roadmap">Roadmap</a>
            </div>
            <div className="item"><div className="icon"><FaRegNewspaper/></div>
              <a href="/whitepaper">Whitepaper</a>
            </div>

            {isUser ?
              <div className="item"><div className="icon logout"><RiLogoutBoxLine/></div>
                <a onClick={()=>LogOut()}>Sign Out</a>
              </div>:
              <div className="item"><div className="icon login"><RiLogoutBoxLine/></div>
                <a href="/login">Sign In</a>
              </div>
            }
            <div className="mail">
              <div class="content"><p>Contact: support@maggots.network</p></div>
            </div>
            
          </div>
        }
      </div>
    </div>
  </>)
}
