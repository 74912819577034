import { Routes, Route } from "react-router-dom"
import React from "react"
import Loadable from "react-loadable"

import "react-toastify/dist/ReactToastify.css"
import "../src/style/Main.scss"
import "../src/style/Menu.scss"

import Navbar from "./components/Navbar"

import Loader from "./components/Loader"

const Home = Loadable({loader: () => import("./pages/Home"), loading: Loader})
const Login = Loadable({loader: () => import("./pages/Login"), loading: Loader})
const Signup = Loadable({loader: () => import("./pages/Signup"), loading: Loader})

const Airdrop = Loadable({loader: () => import("./pages/Airdrop"), loading: Loader})
const Referral = Loadable({loader: () => import("./pages/Referral"), loading: Loader})
const Whitepaper = Loadable({loader: () => import("./pages/Whitepaper"), loading: Loader})
const Roadmap = Loadable({loader: () => import("./pages/Roadmap"), loading: Loader})
const Account = Loadable({loader: () => import("./pages/Account"), loading: Loader})
//const Dapps = Loadable({loader: () => import("./pages/Dapps"), loading: Loader})
const Password = Loadable({loader: () => import("./pages/Password"), loading: Loader})

const Admin = Loadable({loader: () => import("./pages/Admin"), loading: Loader})

export default function App() {
  return (
    <div id="outer-container">
      <div id="screenmod">
        <Navbar/>

        <div className="main">
          
            <Routes>
              <Route path="/mining" element={<Airdrop/>}/>
              <Route path="/team" element={<Referral/>}/>
              <Route path="/account" element={<Account/>}/>
              <Route path="/admin" element={<Admin/>}/>

              <Route path="/whitepaper" element={<Whitepaper/>}/>
              <Route path="/roadmap" element={<Roadmap/>}/>

              <Route path="/login" element={<Login/>}/>
              <Route path="/signup/*" element={<Signup/>}/>
              <Route path="/password" element={<Password/>}/>
              <Route path="/*" element={<Home/>}/>
            </Routes>

        </div>

      </div>
    </div>
  )
}
