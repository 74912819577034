import React  from "react"

export default function Loader({ isLoading, error }) {
  // Handle the loading state
  if (isLoading) {
    return (
      <div className="loaderComponents scaleAnimation">
        <img src={require("../assets/token100.png")} alt=""/>
      </div>
    )
  }
  // Handle the error state
  else if (error) {
    return <div style={{marginTop:"30%", textAlign:"center", color: "#fff"}}>Loading Error</div>
  } else {
    return null
  }
};